<template>
  <DetailTemplate
    :customClass="'lead-detail detail-page'"
    v-if="getPermission('lead:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex">
        <template v-if="pageLoading">
          <v-skeleton-loader
            class="custom-skeleton height-40px custom-skeleton-full-width"
            width="600"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <h1 class="form-title custom-nowrap-ellipsis text-capitalize mb-0">
            {{ pageTitle }}
          </h1>
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <v-btn
        class="mx-2 custom-bold-button white--text"
        color="cyan"
        :disabled="pageLoading || !costingObject.pdf_url"
        v-on:click="downloadPdf"
      >
        Download <v-icon small>mdi-file-pdf-box</v-icon>
      </v-btn>
      <v-btn
        :disabled="pageLoading"
        class="mx-2 custom-bold-button white--text"
        color="cyan"
        v-on:click="
          $router.push(
            getDefaultRoute('project.costing.update', {
              params: {
                id: projectCosting,
              },
              query: {
                project: costingObject.project.id,
              },
            })
          )
        "
      >
        Edit <v-icon small>mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <v-container fluid>
        <v-row>
          <v-col md="6">
            <v-layout>
              <v-flex md4>
                <label class="font-weight-500 font-size-16">Reference #</label>
              </v-flex>
              <v-flex md8>
                <label
                  v-if="costingObject.soled_job_no"
                  class="font-weight-600 font-size-16"
                  >{{ costingObject.soled_job_no }}</label
                >
                <em v-else class="text--secondary">No Reference #</em>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md4>
                <label class="font-weight-500 font-size-16">Customer</label>
              </v-flex>
              <v-flex md8>
                <label
                  v-if="lodash.isEmpty(costingObject.customer) === false"
                  class="font-weight-600 font-size-16"
                  >{{ costingObject.customer.company_name }}</label
                >
                <em v-else class="text--secondary">No Customer</em>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md4>
                <label class="font-weight-500 font-size-16"
                  >Customer PO No.</label
                >
              </v-flex>
              <v-flex md8>
                <label
                  v-if="costingObject.customer_po_no"
                  class="font-weight-600 font-size-16"
                  >{{ costingObject.customer_po_no }}</label
                >
                <em v-else class="text--secondary">No Customer PO No.</em>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md4>
                <label class="font-weight-500 font-size-16"
                  >Customer PO Date</label
                >
              </v-flex>
              <v-flex md8>
                <label
                  v-if="costingObject.customer_po_date"
                  class="font-weight-600 font-size-16"
                  >{{ formatDate(costingObject.customer_po_date) }}</label
                >
                <em v-else class="text--secondary">No Customer PO Date</em>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md4>
                <label class="font-weight-500 font-size-16">Quotation #</label>
              </v-flex>
              <v-flex md8>
                <label
                  v-if="lodash.isEmpty(costingObject.quotation) === false"
                  class="font-weight-600 font-size-16"
                  >{{ costingObject.quotation.barcode }}</label
                >
                <em v-else class="text--secondary">No Quotation #</em>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md4>
                <label class="font-weight-500 font-size-16">Prepared By</label>
              </v-flex>
              <v-flex md8>
                <label
                  v-if="costingObject.prepared_by"
                  class="font-weight-600 font-size-16"
                  >{{ costingObject.prepared_by }}</label
                >
                <em v-else class="text--secondary">No Prepared By</em>
              </v-flex>
            </v-layout>
          </v-col>
          <v-col md="6">
            <v-layout>
              <v-flex md4>
                <label class="font-weight-500 font-size-16">VO</label>
              </v-flex>
              <v-flex md8>
                <label
                  v-if="costingObject.vo"
                  class="font-weight-600 font-size-16"
                  >{{ costingObject.vo }}</label
                >
                <em v-else class="text--secondary">No VO</em>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md4>
                <label class="font-weight-500 font-size-16">Project Name</label>
              </v-flex>
              <v-flex md8>
                <label
                  v-if="lodash.isEmpty(costingObject.project) === false"
                  class="font-weight-600 font-size-16"
                  >{{ costingObject.project.name }}</label
                >
                <em v-else class="text--secondary">No Project Name</em>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md4>
                <label class="font-weight-500 font-size-16"
                  >Project Value (PO)</label
                >
              </v-flex>
              <v-flex md8>
                <label
                  v-if="costingObject.project_po"
                  class="font-weight-600 font-size-16"
                  >{{
                    $accountingJS.formatMoney(costingObject.project_po)
                  }}</label
                >
                <em v-else class="text--secondary">No Project Value (PO)</em>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md4>
                <label class="font-weight-500 font-size-16"
                  >Project Value (VO)</label
                >
              </v-flex>
              <v-flex md8>
                <label
                  v-if="costingObject.project_vo"
                  class="font-weight-600 font-size-16"
                  >{{
                    $accountingJS.formatMoney(costingObject.project_vo)
                  }}</label
                >
                <em v-else class="text--secondary">No Project Value (VO)</em>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md4>
                <label class="font-weight-500 font-size-16">Total Price</label>
              </v-flex>
              <v-flex md8>
                <label
                  v-if="costingObject.total_price"
                  class="font-weight-600 font-size-16"
                  >{{
                    $accountingJS.formatMoney(costingObject.total_price)
                  }}</label
                >
                <em v-else class="text--secondary">No Total Price</em>
              </v-flex>
            </v-layout>
          </v-col>
          <v-col md="12">
            <v-simple-table class="simple-table">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="simple-table-th" width="40px">S.No.</th>
                    <th class="simple-table-th" width="200px">Supplier</th>
                    <th class="simple-table-th">Supplier Quotation #</th>
                    <th class="simple-table-th">Description</th>
                    <th class="simple-table-th" width="200px">Amount (SGD)</th>
                    <th class="simple-table-th" width="200px">Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      class="simple-table-td font-size-16"
                      colspan="4"
                      align="right"
                    >
                      Total Price :
                    </td>
                    <td class="simple-table-td font-size-16" width="200px">
                      {{
                        $accountingJS.formatMoney(
                          costingObject.total_price || 0
                        )
                      }}
                    </td>
                    <td class="simple-table-td" width="200px"></td>
                  </tr>
                  <tr v-for="(item, index) in costingItems" :key="index">
                    <td class="simple-table-td">{{ index + 1 }}</td>
                    <td class="simple-table-td" width="200px">
                      <label v-if="item.supplier" class="font-size-16">{{
                        item.supplier
                      }}</label>
                      <em v-else class="text--secondary">No Supplier</em>
                    </td>
                    <td class="simple-table-td">
                      <label
                        v-if="item.supplier_barcode"
                        class="font-size-16"
                        >{{ item.supplier_barcode }}</label
                      >
                      <em v-else class="text--secondary"
                        >No Supplier Quotation #</em
                      >
                    </td>
                    <td class="simple-table-td">
                      <label v-if="item.description" class="font-size-16">{{
                        item.description
                      }}</label>
                      <em v-else class="text--secondary">No Description</em>
                    </td>
                    <td class="simple-table-td" width="200px">
                      <label v-if="item.amount" class="font-size-16">{{
                        $accountingJS.formatMoney(item.amount)
                      }}</label>
                      <em v-else class="text--secondary">No Amount (SGD)</em>
                    </td>
                    <td class="simple-table-td" width="150px">
                      <label v-if="item.remarks" class="font-size-16">{{
                        item.remarks
                      }}</label>
                      <em v-else class="text--secondary">No Remarks</em>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="simple-table-td font-size-16"
                      colspan="4"
                      align="right"
                    >
                      Total Cost :
                    </td>
                    <td class="simple-table-td font-size-16" width="200px">
                      {{ $accountingJS.formatMoney(costingObject.total_cost) }}
                    </td>
                    <td class="simple-table-td" width="200px"></td>
                  </tr>
                  <tr>
                    <td
                      class="simple-table-td font-size-16"
                      colspan="4"
                      align="right"
                    >
                      Profit :
                    </td>
                    <td class="simple-table-td font-size-16" width="200px">
                      {{ $accountingJS.formatMoney(costingObject.profit) }}
                    </td>
                    <td class="simple-table-td" width="200px"></td>
                  </tr>
                  <tr>
                    <td
                      class="simple-table-td font-size-16"
                      colspan="4"
                      align="right"
                    >
                      Profit Percentage :
                    </td>
                    <td class="simple-table-td font-size-16" width="200px">
                      {{
                        $accountingJS.formatNumber(
                          costingObject.profit_percentage
                        )
                      }}%
                    </td>
                    <td class="simple-table-td" width="200px"></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </DetailTemplate>
</template>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET } from "@/core/services/store/request.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
export default {
  mixins: [CommonMixin],
  name: "detail-costing",
  data() {
    return {
      projectCosting: 0,
      pageLoading: true,
      costingObject: {
        barcode: null,
        pdf_url: null,
        reference: null,
        soled_job_no: null,
        customer: {},
        project: {},
        quotation: {},
        customer_po_no: null,
        customer_po_date: null,
        prepared_by: null,
        quotation_barcode: null,
        vo: null,
        project_po: null,
        project_vo: null,
        total_price: null,
        total_cost: null,
        profit: null,
        profit_percentage: null,
        created_at: null,
        modified_at: null,
      },
      costingItems: [],
    };
  },
  components: {
    DetailTemplate,
  },
  methods: {
    downloadPdf() {
      window.open(
        process.env.VUE_APP_API_URL +
          "project/costing/" +
          this.projectCosting +
          "/download",
        "_blank"
      );
    },
    getProjectCosting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "project/costing/" + _this.projectCosting,
        })
        .then(({ data }) => {
          _this.costingObject = {
            barcode: data.barcode,
            pdf_url: data.pdf_url,
            reference: data.reference,
            soled_job_no: data.soled_job_no,
            customer: data.customer,
            project: data.project,
            quotation: data.quotation,
            customer_po_no: data.customer_po_no,
            customer_po_date: data.customer_po_date,
            prepared_by: data.prepared_by,
            quotation_barcode: data.quotation_barcode,
            vo: data.vo,
            project_po: data.project_po,
            project_vo: data.project_vo,
            total_price: data.total_price,
            total_cost: data.total_cost,
            profit: data.profit,
            profit_percentage: data.profit_percentage,
            created_at: data.created_at,
            modified_at: data.modified_at,
          };
          _this.costingItems = [];
          for (let i = 0; i < data.items.length; i++) {
            _this.costingItems.push({
              supplier: data.items[i].company_name,
              supplier_barcode: data.items[i].supplier_quote,
              description: data.items[i].description,
              amount: data.items[i].amount,
              remarks: data.items[i].remarks,
            });
          }
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Project", route: "project" },
      { title: "Costing" },
      { title: "Detail" },
    ]);
  },
  created() {
    this.projectCosting = this.lodash.toSafeInteger(this.$route.params.id) || 0;
    if (this.projectCosting > 0) {
      this.getProjectCosting();
    } else {
      this.goBack();
    }
  },
  computed: {
    customerName() {
      return this.lodash.isEmpty(this.costingObject.customer) === false
        ? this.costingObject.customer.company_name
        : null;
    },
    pageTitle() {
      return "Project Costing For " + this.customerName;
    },
  },
};
</script>
